import React, { useEffect, useState } from 'react'
import {ReactComponent as Circle} from '../assets/svg/circle.svg';
import Message from './Message'
import './Typing.css';

function Typing({children}) {
    const [dotColors, setDotColors] = useState(['#9E9EA1', '#B6B5B9', '#B6B5B9']);
    const [hideDots,setHideDots] = useState(false);
    useEffect(() => {
        const interval = setInterval(() => {
          setDotColors((prevColors) => [
            prevColors[1],
            prevColors[2],
            prevColors[0],
          ]);
        }, 400);
        return () => clearInterval(interval);
    },[]);

    useEffect(() => {
        const disappearTimeout = setTimeout(() => {
          // Call the onDisappear callback passed as a prop
          setHideDots(true);
        }, Math.floor(Math.random() * (1500 - 500 + 1) + 500)); // Disappear after 2 seconds
    
        return () => clearTimeout(disappearTimeout);
      },[]);
  return (
    <div>{!hideDots ? <Message><Circle style={{fill: dotColors[2]}}/><Circle style={{fill: dotColors[1]}}/><Circle style={{fill: dotColors[0]}}/></Message> : children}</div>
  )
}

export default Typing