import React from 'react'
import './Header.css';
import SwitchLanguage from './SwitchLanguage';

function Header({language,setLanguage}) {
  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-light ps-3 pe-3">
      <div className='container-fluid d-flex justify-content-between align-items-center'>
      <span className="navbar-brand d-flex align-items-center">
                <img src={require('../assets/img/ksk.png')} width="50" height="50" className="d-inline-block d-none d-sm-inline align-top" alt="Logo"/>
                <span className="fs-4 ms-2">Københavns Styrkeløft Klub</span>
            </span>
          <div className="d-flex ms-auto align-items-center">
            <ul className="navbar-nav me-auto">
                <li className="nav-item">
                    <SwitchLanguage language={language} setLanguage={setLanguage}/>
                </li>
            </ul>
        </div>
        </div>
      </nav>
  )
}

export default Header