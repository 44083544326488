import React from 'react';
import './Message.css';
import {ReactComponent as Tail} from '../assets/svg/tail.svg';

function Message({children,sender=false}) {
  var msgClass = '';
  if(sender){
    msgClass = 'sender';
  }
    return (
        <div className={`message-bubble ${msgClass}`}>
          {!sender && <div className="messagebubble-avatar" />}
          <div className="message-send-time">
            <div className="bubble-tail">
              {!sender && <Tail/>}
              <div className="bubble">
                <div className="message">{children}</div>
              </div>
              {sender && <Tail/>}
            </div>
          </div>
        </div>
    )
}

export default Message
