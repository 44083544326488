import React from 'react';
import people from '../assets/img/kskpeople.png';
import './ImageMessage.css';

//Merge this with Message.js

function ImageMessage() {
    return (
        <div className="message-bubble-img">
          <div className="messagebubble-avatar" />
          <div className="message-send-time">
            <div className="bubble-tail">
              <div className="bubble">
                <img className="rectangle" alt="Rectangle" src={people} />
              </div>
            </div>
          </div>
        </div>
      );
}

export default ImageMessage