import React from 'react';
import Flag from './Flag';



function SwitchLanguage({language,setLanguage}) {
    const onClick = () => {
        if(language === 'dk'){
            setLanguage('en');
        }else{
            setLanguage('dk');
        }
    }
  return (
    <button name='language' style={{display:'block',height:'50px',width:'50px',borderRadius:'50%',backgroundColor:'transparent',border:'0px'}} onClick={onClick}><Flag language={language} /></button>
  )
}

export default SwitchLanguage