import React from 'react';
import {ReactComponent as Dkflag} from '../assets/svg/flags/dk.svg';
import {ReactComponent as Usflag} from '../assets/svg/flags/us.svg';

function Flag({language}) {
  return (
    <>
      {language === 'dk' ? 
        <Usflag style={{ width: 'auto', height:'auto' }} /> :
        <Dkflag style={{ width: 'auto', height:'auto' }} />
      }
    </>
  )
}

export default Flag