

function ActionGroup({elements}) {
  return (
    <>{elements.map((element, index) => (
      <div key={element.id || index}>
        {element}
      </div>
    ))}</>
  )
}

export default ActionGroup