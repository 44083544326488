import React from 'react'
import Typing from './Typing'
import Message from './Message'
import TextLanguage from './TextLanguage'

function Socials({language}) {
  return (
    <div>
        <Message sender={true}><TextLanguage dk={'Hvor kan jeg se mere'} en={'Where can I see more'} language={language} />? 📺</Message>
      <Typing>
        <Message><TextLanguage dk={'Du kan finde os på vores sociale medier.'} en={'You can find us on our social media'} language={language}  /></Message>
        <Message><a href='https://www.facebook.com/kskstyrke' target='blank'><TextLanguage dk={'Vi er på'} en={'We are on'} language={language} /> Facebook</a></Message>
        <Message><a href='https://www.instagram.com/koebenhavnsstyrkeloeftklub' target='blank'><TextLanguage dk={'Og'} en={'And'} language={language} /> Instagram</a></Message>
      </Typing>
    </div>
  )
}

export default Socials