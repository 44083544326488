import React from 'react'
import Message from './Message'
import Typing from './Typing'
import TextLanguage from './TextLanguage'

function Location({language}) {
  return (
    <div>
        <Message sender={true}><TextLanguage dk={'Hvor kan jeg finde jer'} en={'Where can I find you'} language={language} />? 😁 </Message>
        <Typing>
            <Message><TextLanguage dk={'Du kan finde os her'} en={'You can find us here'} language={language} />:<br/>
                <a href='https://maps.app.goo.gl/pjyM1s8gYxFHBZWy9' target='blank'>Hvidkildevej 64, 2400 København</a>
            </Message>
            <iframe title='Lokation' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2248.3438830944306!2d12.515959500000001!3d55.7003941!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46525232c1d0b8bd%3A0x6d2c78b0473528e4!2sKSK%20-%20K%C3%B8benhavns%20Styrkel%C3%B8fter%20Klub!5e0!3m2!1sda!2sdk!4v1706358292209!5m2!1sda!2sdk" width="100%" height="450" style={{border:0,marginBottom:40}} loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe><br/>
        </Typing>
    </div>
  )
}

export default Location