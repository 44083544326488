import './App.css';
import ActionButton from './components/ActionButton';
import ActionGroup from './components/ActionGroup';
import Header from './components/Header';
import ImageMessage from './components/ImageMessage';
import Message from './components/Message';
import { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import TextLanguage from './components/TextLanguage';

function App() {
  const [showTrial, setShowTrial] = useState(false);
  const [showOpeningHours, setshowOpeningHours] = useState(false);
  const [showLocation, setShowLocation] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const [elements, setElements] = useState([]);
  const [language, setLanguage] = useState('dk');
  return (
    <div className="App">
      <Header language={language} setLanguage={setLanguage} />
      <div className='scrollContainer'>
        <div className='content'>
          <Message message={''}><TextLanguage language={language} dk={'Velkommen til'} en={'Welcome'}/>! 🎉</Message>
          <ImageMessage/>
          <Message message={''}><TextLanguage language={language} dk={'Det ultimative fællesskab for styrkeløftentusiaster i København og omegn'} en={'The ultimate community for powerlifters in copenhagen'} />. 🙌</Message>
          <ActionGroup elements={elements}></ActionGroup>
        </div>
        <div className='loading'></div>
        <div className='actionButtons'>
          {!showTrial ? <ActionButton language={language} text={<TextLanguage language={language} dk={'Prøvetræning'} en={'Trial'}/>} actionType={'trial'} func={setShowTrial} setElements={setElements} elements={elements} /> : <></>}
          {!showOpeningHours ? <ActionButton language={language} text={<TextLanguage language={language} dk={'Åbningstider'} en={'Opening Hours'} />} actionType={'hours'} func={setshowOpeningHours} setElements={setElements} elements={elements} /> : <></>}
          {!showLocation ? <ActionButton language={language} text={<TextLanguage language={language} dk={'Lokation'} en={'Location'} />} actionType={'maps'} func={setShowLocation} setElements={setElements} elements={elements} /> : <></>}
          {showTrial && showOpeningHours && showLocation && !showMore && <ActionButton language={language} text={<TextLanguage language={language} dk={'Se mere'} en={'More'} />} actionType={'more'} func={setShowMore} setElements={setElements} elements={elements} />}
        </div>
      </div>
    </div>
  );
}

export default App;
