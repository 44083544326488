import React from 'react';
import './ActionButton.css';
import Trial from './Trial';
import OpeningHours from './OpeningHours';
import Location from './Location';
import Socials from './Socials';

function ActionButton({text,func,setElements,elements,actionType,language}) {
  const onClick = () => {
    func(true);
    var newElement = <></>;
    switch (actionType) {
      case 'trial':
          newElement = <Trial language={language}/>
        break;
      case 'hours':
        newElement = <OpeningHours language={language}/>
        break;
      case 'maps':
        newElement = <Location language={language}/>
        break;
      case 'more':
        newElement = <Socials language={language}/>
        break;
      default:
        break;
    }

    // Update the state to include the new element
    setElements([...elements, newElement]);
  }
    return (
        <div className="box" onClick={onClick}>
          <div className="group">
            <div className="overlap-group">
              <div className="rectangle" />
              <div className="text-wrapper">{text}</div>
            </div>
          </div>
        </div>
      );
}

export default ActionButton